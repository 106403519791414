import Swiper, { Navigation } from "swiper";

window.addEventListener("load", function () {
  const swipers = document.body.querySelectorAll(".swiper-news");

  Swiper.use([Navigation]);

  swipers.forEach((swiper, i) => {
    new Swiper(".news-swiper", {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 80,
      grabCursor: true,
      freeMode: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  });
});
